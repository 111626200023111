body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


a {
  color: #61dafb;
  text-decoration: none;
}

input, button {
  padding: 10px 20px; 
  background: rgba(0,0,0,.15); 
  border:none;
  border-radius: 4px; 
  font-size: 14pt;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  color: #eee;
  transition: .2s;
}

input:hover, button:hover{
  background: rgba(0,0,0,.2);
}


input:focus, button:hover{
  background: rgba(0,0,0,.3);
}


button{
  padding: 10px 40px;
  border-radius: 30px;
  cursor: pointer;
  margin-top: 20px;
}
