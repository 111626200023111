.app {
  display: flex;
  min-height: 100vh;
  text-align: center;
  background-color: #282c34;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.card {
  padding: 40px;
  text-align: center;
  background: #212121;
  box-shadow: 2px 4px 10px rgba(0,0,0,.3);
  background-image: url("/bg.jpg");
  background-size: cover;
  background-position: center;
}

.messages {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.error {
  position: absolute;
  z-index: 9;
  top: 28%;
  padding: 10px 20px;
  background: #dc3545;
  border-radius: 4px;
  box-shadow: 2px 4px 10px rgba(0,0,0,.15);
  color: white;
  font-size: 12pt;
  animation: appears .5s forwards;
}

.subtitle {
  opacity: .7;
  font-size: 9pt;
}

.hidden {
  display: none;
}

@keyframes appears{
  0%{opacity: 0; margin-left: 0;}
  10%{margin-left:-10px;}
  20%{margin-left:10px;}
  30%{margin-left:-10px;}
  40%{margin-left:10px;}
  50%{margin-left:-10px;}
  60%{margin-left:10px;}
  70%{margin-left:-10px;}
  80%{margin-left:10px;}
  90%{margin-left:-10px;}
  100%{opacity: 1; margin-left: 0;}
}


.spinner{ display: inline-block;position: relative; width: 164px; height: 164px; margin: 20px; pointer-events: none;}
.spinner-point{position: absolute; z-index: 1; width: 16%; height: 16%; background: #fff; border-radius: 100%;}
.spinner-point:nth-child(1){top: 0; left: 42%;}
.spinner-point:nth-child(2){top: 21%; left: 6%;}
.spinner-point:nth-child(3){bottom: 21%; left: 6%;}
.spinner-point:nth-child(4){bottom: 0; left: 42%;}
.spinner-point:nth-child(5){right: 6%; bottom: 21%;}
.spinner-point:nth-child(6){top: 21%; right: 6%;}

.spinner-line{position: absolute; width: 2%; background: #fff; box-shadow: 0 -2px 2px rgba(255, 255, 255, 1); transform-origin: 50% 0;}
.spinner-line:nth-child(7){top: 29%; left: 13%; width: 2%; height: 42%; opacity: 1.0; box-shadow: 0 -2px 2px rgba(255, 255, 255, 1); animation: spinner-lines 2s linear infinite; animation-delay: -1.0s;}
.spinner-line:nth-child(8){top: 29%; left: 13%; width: 1.8%; height: 42%; opacity: .8; box-shadow: 0 -2px 2px rgba(255, 255, 255, .8); animation: spinner-lines 2s linear infinite; animation-delay: -.8s;}
.spinner-line:nth-child(9){top: 29%; left: 13%; width: 1.5%; height: 42%; opacity: .6; box-shadow: 0 -2px 2px rgba(255, 255, 255, .6); animation: spinner-lines 2s linear infinite; animation-delay: -.6s;}
.spinner-line:nth-child(10){top: 29%; left: 13%; width: 1.0%; height: 42%; opacity: .4; box-shadow: 0 -2px 2px rgba(255, 255, 255, .4); animation: spinner-lines 2s linear infinite; animation-delay: -.4s;}
.spinner-line:nth-child(11){top: 29%; left: 13%; width: .5%; height: 42%; opacity: .2; box-shadow: 0 -2px 2px rgba(255, 255, 255, .2); animation: spinner-lines 2s linear infinite; animation-delay: -.2s;}
.spinner-line:nth-child(12){top: 29%; left: 13%; width: .1%; height: 42%; opacity: .1; box-shadow: 0 -2px 2px rgba(255, 255, 255, .1); animation: spinner-lines 2s linear infinite; animation-delay: -.0s;}


@keyframes spinner-lines{
  0%{left: 13%; transform: rotate(0deg) scaleY(1); transform-origin: 50% 0;}
 10%{left: 13%; transform: rotate(0deg) scaleY(0); transform-origin: 50% 0;}
 10.1%{left: 13%; transform: rotate(-60deg) scaleY(0); transform-origin: 50% 0;}
 20%{left: 13%; transform: rotate(-60deg) scaleY(2); transform-origin: 50% 0;}
 20.1%{left: 85%; transform: rotate(-60deg) scaleY(2); transform-origin: 50% 100%;}
 30%{left: 85%; transform: rotate(-60deg) scaleY(0); transform-origin: 50% 100%;}
 30.1%{left: 85%; transform: rotate(0deg) scaleY(0); transform-origin: 50% 100%;}

 40%{left: 85%; transform: rotate(0deg) scaleY(1); transform-origin: 50% 100%;}
 40.1%{left: 85%; transform: rotate(0deg) scaleY(1); transform-origin: 50% 0;}

 50%{left: 85%; transform: rotate(0deg) scaleY(0); transform-origin: 50% 0;}
 50.1%{left: 85%; transform: rotate(0deg) scaleY(0); transform-origin: 50% 0;}

 60%{left: 85%; transform: rotate(0deg) scaleY(0); transform-origin: 50% 0;}
 60.1%{left: 85%; transform: rotate(60deg) scaleY(0); transform-origin: 50% 0;}

 70%{left: 85%; transform: rotate(60deg) scaleY(2); transform-origin: 50% 0;}
 70.1%{left: 13%; transform: rotate(60deg) scaleY(2); transform-origin: 50% 100%;}

 80%{left: 13%; transform: rotate(60deg) scaleY(0); transform-origin: 50% 100%;}
 80.1%{left: 13%; transform: rotate(0deg) scaleY(0); transform-origin: 50% 100%;}

 90%{left: 13%; transform: rotate(0deg) scaleY(1); transform-origin: 50% 100%;}
 90.1%{left: 13%; transform: rotate(0deg) scaleY(1); transform-origin: 50% 0;}
}
